//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CategorySelector from "./../components/CategorySelector";
import LangSelect from "./../components/LangSelect";
import throttle from "lodash/throttle";
import { get, call, sync } from "vuex-pathify";
import CitySelector from "./CitySelector";
import ClickOutside from "vue-click-outside";
import AvailabilityFilter from "@/components/modals/AvailabilityFilter";

export default {
  data() {
    return {
      searchFocused: false,
      opened: false,
      filterOpen: false,
    };
  },
  directives: {
    ClickOutside,
  },
  components: {
    CitySelector,
    CategorySelector,
    LangSelect,
    AvailabilityFilter,
  },
  computed: {
    cityId: get("settings/cityId"),
    searchHighlights: get("map/searchHighlights"),
    query: sync("map/search"),
    category: sync("disabilitiesCategorySettings/category"),
    popupOpen: sync("disabilitiesCategorySettings/popupOpen"),
    mobileMenu: get("settings/mobileMenu"),
  },
  watch: {
    cityId: function(cityId) {
      this.query = ''
      this.search({ query: this.query, cityId })
    }
  },
  methods: {
    ...call("map", ["clearCategories"]),
    mainPageMobOpened: call("settings/menuOpen"),
    emitCities(event) {
      this.$emit("open-list", event);
    },
    setClickedObj(id) {
      this.$store.commit("map/SET_CLICKED_OBJECT_ID", id);
    },
    closeSearch() {
      this.searchFocused = false;
    },
    search: throttle(call("map/search"), 1000),
    openAvailabilityFilter() {
      this.filterOpen = !this.filterOpen;
    },
    enableVisualImpairedMode: call("visualImpairedModeSettings/enable"),
  },
};
