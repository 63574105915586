//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sync, call, get } from "vuex-pathify";
export default {
  name: "AvailabilityFilter",
  computed: {
    ...sync("map", ["accessibilityLevels"]),
  },
  methods: {
    ...call("map", ["toggleCategory", "toggleAccessibilityLevel"]),
    open() {
      this.availabilityToggle = true;
    },
    close() {
      this.$emit("close");
    },
  },
};
