//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { call, get, sync } from "vuex-pathify";

export default {
  data() {
    return {
      selectedCategory: null,
      availabilityToggle: false,
    };
  },
  methods: {
    selectCategory(cat) {
      this.selectedCategory = cat;
    },
    ...call("map", ["toggleCategory", "toggleAccessibilityLevel"]),
  },
  computed: {
    ...sync("map", ["accessibilityLevels"]),
    selectedCategories: get("map/selectedCategories"),
    categories() {
      return this.$store.state.objectCategories.categories;
    },
  },
};
